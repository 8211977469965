'use client';

import { FC, ReactNode, useEffect, useState, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { IoIosArrowDown } from 'react-icons/io';

import Heading from '@/components/common/Heading';
import Text from '@/components/common/Text';
import { RenderHTML } from '../renderHtml';

export const LandingAccordion: FC<{
  title?: string;
  description?: string;
  className?: string;
  children?: ReactNode;
  open?: boolean;
  setOpen?: any;
  headerBold?: boolean;
  [key: string]: any;
}> = ({
  title,
  description,
  className,
  children,
  open = false,
  headerBold = true,
  setOpen = () => {},
  ...props
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState('0px');
  const [opacity, setOpacity] = useState(0);

  const handleClick = () => {
    setIsOpen(!isOpen);
    setOpen(title);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen) {
      setHeight(`${contentRef.current?.scrollHeight}px`);
      setOpacity(1);
    } else {
      setHeight('0px');
      setOpacity(0);
    }
  }, [isOpen]);

  return (
    <div
      className={twMerge(
        'mrcool-accordion flex w-full flex-col items-stretch justify-center md:gap-y-1',
        className
      )}
      {...props}
    >
      <button
        type='button'
        className='mrcool-accordion-trigger flex w-full flex-row items-center justify-between gap-x-4'
        onClick={handleClick}
      >
        {title && (
          <Heading
            size={5}
            className={`mrcool-accordion-title mb-2 text-left text-base md:mb-0 md:text-2xl ${!headerBold && 'font-medium'}`}
          >
            <RenderHTML data={title} />
          </Heading>
        )}
        <div className='mrcool-accordion-icon aspect-square md:h-7 md:w-7'>
          <IoIosArrowDown
            className='duration-800 h-7 w-7 transform transition-transform ease-in-out'
            style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
          />
        </div>
      </button>

      <div
        ref={contentRef}
        style={{
          height: height,
          opacity: opacity,
          transition: 'height 800ms ease-in-out, opacity 800ms ease-in-out',
        }}
        className='mrcool-accordion-content overflow-hidden'
      >
        <div className='mrcool-accordion-content-inner mb-3 w-full pr-9 md:mb-0'>
          {description && !children && (
            <Text size='paragraph'>
              <RenderHTML data={description} />
            </Text>
          )}
          {children && <Text size='paragraph'>{children}</Text>}
        </div>
      </div>
      <hr className='mrcool-accordion-divider' />
    </div>
  );
};
