import { FC } from 'react';
import { RenderHTML } from '../renderHtml';
import { twMerge } from 'tailwind-merge';

export interface TextWithDropShadowColor {
  text: string;
  shadow: string;
}

interface Props {
  text: string;
  color: TextWithDropShadowColor;
  displayShadow?: boolean;
}

export const TextWithDropShadow: FC<Props> = ({
  text,
  color,
  displayShadow = true,
}) => {
  return (
    <div className='relative inline-block'>
      <RenderHTML
        className={twMerge('relative z-10', color.text)}
        data={text}
      ></RenderHTML>

      {displayShadow && (
        <span
          className={twMerge('absolute inset-0 z-0', color.shadow)}
          style={{ filter: 'drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5))' }}
        >
          <RenderHTML data={text}></RenderHTML>
        </span>
      )}
    </div>
  );
};
