'use client';
import { FC, useState, ReactNode } from 'react';
import Heading from '../../common/Heading';
import { LandingAccordion } from '../../landing/LandingAccordion';

interface PropsData {
  id: string;
  data: {
    id: string;
    title: string;
    content: string;
    answer?: string;
    question?: string;
  }[];
}

export const LandingFaqs: FC<{
  content: PropsData;
  [key: string]: any;
}> = ({ content, ...props }) => {
  const [accordionOpen, setAccorOpen] = useState<any>(
    content?.data?.[0]?.title || content?.data?.[0]?.question || ''
  );
  const handleToggle = (key: any) => {
    setAccorOpen(accordionOpen !== key ? key : null);
  };
  return (
    <>
      <section className='LandingFaqs relative flex flex-col gap-y-12 py-14 md:py-20 lg:gap-y-16 xl:py-24'>
        <div className='mx-auto w-full max-w-screen-2xl px-5 md:px-16 xl:px-[7.5rem]'>
          <div className='flex-row gap-y-11 sm:grid-cols-2 md:grid-cols-2 md:gap-y-0 lg:flex'>
            <div className='mb-5 lg:min-w-[400px]'>
              <Heading size={3} className='text-start'>
                Frequently Asked Questions.
              </Heading>
            </div>

            <div className='flex flex-col items-stretch justify-start gap-y-7 md:gap-y-10'>
              { content?.data.map((item: any, i: number) => (
                <LandingAccordion
                  key={i}
                  title={item.title || item.question}
                  description={item.content || item.answer}
                  open={accordionOpen === (item.title || item.question) ? true : false}
                  setOpen={handleToggle}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
